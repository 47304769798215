import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { DataPropsPage } from "../contracts/data-props-page"

const PolitykaCookies: React.FC<PageProps<DataPropsPage>> = ({
  data,
  location,
}) => {
  const post = data.markdownRemark
  return (
    <Layout title={post.frontmatter.title} location={location}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1>{post.frontmatter.title}</h1>
          <h2>{post.frontmatter.description}</h2>
          <p>{post.frontmatter.date}</p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </article>
    </Layout>
  )
}

export default PolitykaCookies

export const query = graphql`
  {
    markdownRemark(frontmatter: { layout: { eq: "cookies" } }) {
      excerpt
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "pl")
        description
      }
    }
  }
`
